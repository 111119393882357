// class Heart extends mojs.CustomShape {
//   getShape () {
//     return '<path d="M73.6170213,0 C64.4680851,0 56.5957447,5.53191489 51.7021277,13.8297872 C50.8510638,15.3191489 48.9361702,15.3191489 48.0851064,13.8297872 C43.4042553,5.53191489 35.3191489,0 26.1702128,0 C11.9148936,0 0,14.0425532 0,31.2765957 C0,48.0851064 14.893617,77.8723404 47.6595745,99.3617021 C49.1489362,100.212766 50.8510638,100.212766 52.1276596,99.3617021 C83.8297872,78.5106383 99.787234,48.2978723 99.787234,31.2765957 C100,14.0425532 88.0851064,0 73.6170213,0 L73.6170213,0 Z"></path>';
//   }
// }
// mojs.addShape( 'heart', Heart );
//
// const CIRCLE_RADIUS = 20;
// const RADIUS = 32;
// const circle = new mojs.Shape({
//   left: 0, top: 0,
//   stroke:   { '#E5214A' : '#CC8EF5' },
//   strokeWidth: { [2*CIRCLE_RADIUS] : 0 },
//   fill:       'none',
//   scale:      { 0: 1 },
//   radius:     CIRCLE_RADIUS,
//   duration:   400,
//   easing:     'cubic.out'
// });
//
// const burst = new mojs.Burst({
//   left: 0, top: 0,
//   radius:   { 4: RADIUS },
//   angle:    45,
//   count:    14,
//   timeline: { delay: 300 },
//   children: {
//     radius:       2.5,
//     fill:         [
//       // { '#91D2FA' : '#BDEFD8' },
//       // { '#91D2FA' : '#ADD6CA' },
//       { '#9EC9F5' : '#9ED8C6' },
//       { '#91D3F7' : '#9AE4CF' },
//
//       { '#DC93CF' : '#E3D36B' },
//       { '#CF8EEF' : '#CBEB98' },
//
//       { '#87E9C6' : '#1FCC93' },
//       { '#A7ECD0' : '#9AE4CF' },
//
//       { '#87E9C6' : '#A635D9' },
//       { '#D58EB3' : '#E0B6F5' },
//
//       { '#F48BA2' : '#CF8EEF' },
//       { '#91D3F7' : '#A635D9' },
//
//       { '#CF8EEF' : '#CBEB98' },
//       { '#87E9C6' : '#A635D9' },
//     ],
//     scale:        { 1: 0, easing: 'quad.in' },
//     pathScale:    [ .8, null ],
//     degreeShift:  [ 13, null ],
//     duration:     [ 500, 700 ],
//     easing:       'quint.out',
//     // speed: .1
//   }
// });
//
// const heart = new mojs.Shape({
//   left: 0, top: 2,
//   shape:    'heart',
//   fill:     '#E5214A',
//   scale:    { 0 : 1 },
//   easing:   'elastic.out',
//   duration: 500,
//   delay:    300,
//   radius:   11,
//   isShowEnd: false
// });
//
// document.addEventListener( 'click', function (e) {
//   const coords = { x: e.pageX, y: e.pageY };
//   burst
//     .tune(coords)
//     .replay();
//
//   circle
//     .tune( coords )
//     .replay();
//
//   heart
//     .tune( coords )
//     .replay();
//
// });
//
// //new MojsPlayer({ add: burst, isPlaying: true, isRepeat: true });

function debounce(func, wait, imediate) {
  let timeout;

  return function () {
    let context = this, args = arguments
    let later = () => {
      timeout = null;
      if (!immediate) func.appply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(late, wait);
    if (callNow) func.apply(context, args);
  }
}

/**
  * throttle function that catches and triggers last invocation
  * use time to see if there is a last invocation
*/
const throttle = (func, limit) => {
  let lastFunc
  let lastRan
  return function() {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function() {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

const ready = (callback) => {
    if (document.readyState!='loading') callback();
    else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
}

(function() {
	'use strict';

	const { body } = document;

  console.log(document.readyState);

  //Detect scroll direction
  const delta = 5;
  let oldScrollY = 0;

  function scrollCheck() {
    const { scrollY } = window;

    if (Math.abs(oldScrollY - scrollY) <= delta || scrollY <= 0) return;

    if (scrollY < oldScrollY && !body.classList.contains('top') && scrollY < 500) {
      body.classList.add('top');
    } else if (scrollY > oldScrollY && scrollY > 100) {
      body.classList.remove('top');
    }

    oldScrollY = scrollY;
  }

  window.onscroll = () => scrollCheck();
  ready(() => {
    const { scrollY } = window;
    if (scrollY < 300) {
      body.classList.add('top');
    }

    const carousel = body.querySelector('.carousel');
    if (carousel) {
      const slides = carousel.querySelectorAll('.carousel_slide');
      const arrows = carousel.querySelectorAll('.carousel_arrow');
      arrows.forEach((arrow) => {
        arrow.addEventListener('click', (e) => {
          if (arrow.classList.contains('left')) carousel.classList.add('back');
          if (arrow.classList.contains('right')) carousel.classList.remove('back');
          slides.forEach((slide) => slide.classList.toggle('active'));
        })
      });
    }
    // const left = carousel.querySelector('.carousel_arrow.left');
    // const right = carousel.querySelector('.carousel_arrow.right');

    // async function supportsWebp() {
    //   if (!self.createImageBitmap) return false;
    //
    //   const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    //   const blob = await fetch(webpData).then(r => r.blob());
    //   return createImageBitmap(blob).then(() => true, () => false);
    // }
    //
    // (async () => {
    //   if(await supportsWebp()) {
    //     body.classList.add('webp');
    //   } else {
    //     body.classList.add('no-webp');
    //   }
    // })();
  });
})();
